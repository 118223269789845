/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import InputWithTooltip from '../../components/Common/InputWithTooltip';
import SearchableSelect from '../../components/Common/CommonSearchableSelect';
import withPostContainer from '../../Container/withPostContainer';
import { platformPlanRateValidationSchema, platformPlanValidationSchema, CsvRateFileValidationSchema } from '../../Validation';
import ErrorMessageComponent from '../../components/Common/FormikErrorMessage';
import TooltipContainer from '../../components/Common/TooltipConatainer';
import { getModulePermission } from '../../Util';
import Loader from '../loader';
import { handleSearchParams } from '../../app/features/CommonSlice';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';
import tickIcon from '../../assets/images/tick.svg';
// import BasicTable from '../../components/Tables/BasicTable';
import CommonTable from '../../components/Tables/CommonTable';
import PaginationComponent from '../../components/Common/Pagination';
import CommonModdal from '../../components/Common/CommonModal';
import AlertIcon from '../../assets/images/alert-icon.svg';
import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
// import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
import { Toast } from '../../components/Common/Toaster';
// import { useHandlePutMutation } from '../../app/features/api/commonPutApi';
import { useHandleUpdateOrDeleteMutation } from '../../app/features/api/CommonChangeApi';

const AddPlan = ({
    id, data, dataLoading,
}) => {
    if (id) {
        getModulePermission('update', 'page');
    } else {
        getModulePermission('create', 'page');
    }
    const dispatch = useDispatch();

    getModulePermission('view', 'page');
    const { searchParams, defaultCurrency } = useSelector((store) => store.common);
    const [openRateForm, setOpenrateForm] = useState(false);
    const [openCSVForm, setOpenCSVForm] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState('');
    const [page, setPage] = useState(1);
    const [rateId, setRateId] = useState();
    const [serverData, setServerData] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [handleGetWithPost] = useHandleGetWithPostMutation();
    // const [handlePut] = useHandlePutMutation();
    const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();

    const { data: rates, isFetching, refetch: refetchRate } = useHandleGetApiQuery({ url: `platformadmin/did_country_charges/${id}/?items_per_page=10&keyword=${searchParams.keyword}&page=${searchParams.page}` }, { skip: typeof searchParams?.page === 'undefined' || typeof id === 'undefined' });
    const { data: countries, isLoading } = useHandleGetApiQuery({ url: 'countries?page=-1&number_of_records=10' });
    const { data: rateDetails } = useHandleGetApiQuery({ url: `platformadmin/did_country_charges/charges/${rateId}` }, { skip: !rateId });
    const navigate = useNavigate();
    const initialValues = {
        country_id: '',
        name: '',
        description: '',
        platform_charge: 0,
        plan_mode: 'monthly',
        local_number_rental_charge: 0,
        toll_free_number_rental_charge: 0,
        local_number_call_charge: 0,
        toll_free_number_call_charge: 0,
        recording_call_charge: 0,
        recording_storage_charge: 0,
        storage_limit: 0,
        enterprice_plan: false,
        sip_forwarding_cost: 0,
        priority: null,
        currencies_id: '',
    };
    const initialRateValues = {
        country_id: '',
        country_code: '',
        local_number_rental_charge: '',
        toll_free_number_rental_charge: '',
        local_number_call_charge: '',
        toll_free_number_call_charge: '',
        forwarding_call_charge: '',
    };
    const onTabChange = (e) => {
        const tab1 = document.getElementById('bacicTab2-1');
        tab1.classList.remove('active');
        tab1.children[0].classList.remove('active');
        const tab2 = document.getElementById('bacicTab2-2');
        tab2.classList.remove('active');
        tab2.children[0].classList.remove('active');
        const actab = document.getElementById(`bacicTab2-${e}`);
        actab.classList.add('active');
        actab.children[0].classList.add('active');

        const tabpan1 = document.getElementById('bacicTab2pan-1');
        tabpan1.classList.remove('active');
        const tabpan2 = document.getElementById('bacicTab2pan-2');
        tabpan2.classList.remove('active');
        const actabpab = document.getElementById(`bacicTab2pan-${e}`);
        actabpab.classList.add('active');
    };
    const formik = useFormik({
        initialValues: serverData || initialValues,
        validationSchema: platformPlanValidationSchema,
        onSubmit: async (values, { setFieldError }) => {
            delete values.updated_by;
            delete values.is_deleted;
            if (defaultCurrency?.id) {
                values.currencies_id = defaultCurrency?.id ? parseInt(defaultCurrency?.id, 10) : '';
            }
            console.log(values);
            values.country_id = parseInt(values.country_id, 10);
            values.platform_charge = values.platform_charge ? parseFloat(values.platform_charge) : 0;
            values.local_number_call_charge = values.local_number_call_charge ? parseFloat(values.local_number_call_charge) : 0;
            values.local_number_rental_charge = values.local_number_rental_charge ? parseFloat(values.local_number_rental_charge) : 0;
            values.toll_free_number_call_charge = values.toll_free_number_call_charge ? parseFloat(values.toll_free_number_call_charge) : 0;
            values.toll_free_number_rental_charge = values.toll_free_number_rental_charge ? parseFloat(values.toll_free_number_rental_charge) : 0;
            values.recording_call_charge = values.recording_call_charge ? parseFloat(values.recording_call_charge) : 0;
            values.storage_limit = !values.storage_limit ? 0 : parseFloat(values.storage_limit);
            values.sip_forwarding_cost = values.sip_forwarding_cost ? parseFloat(values.sip_forwarding_cost) : 0;
            values.recording_storage_charge = !values.recording_storage_charge ? 0 : parseFloat(values.recording_storage_charge);
            const url = id ? `platformadmin/plan/${id}` : 'platformadmin/plan/';
            const res = id
                ? await handleUpdateOrDelete({
                    url: `${url}`,
                    data: values,
                    method: 'PUT',
                })
                : await handleGetWithPost({
                    data: values,
                    url,
                    method: 'POST',
                });
            if (res?.error) {
                const errors = res?.error?.data.errors;
                if (errors) {
                    Object.keys(errors).map((keyName) => setFieldError(keyName, errors[keyName]));
                } else if (res?.error.data?.message) {
                    Toast({ type: 'error', message: res?.error.data?.message });
                }
            }
            if (res?.data.status === 201) {
                Toast({ type: 'success', message: res?.data?.message });
                if (res?.data?.data?.plan_id) {
                    navigate(`/plans/edit-plan/${res?.data?.data?.plan_id}`);
                }
            }
            if (res?.data.status === 200) {
                Toast({ type: 'success', message: res?.data?.message });
                if (res?.data?.data?.plan_id) {
                    navigate(`/plans/edit-plan/${res?.data?.data?.plan_id}`);
                    // onTabChange(2);
                }
                if (id) {
                    onTabChange(2);
                }
            }
            // handlePostRequest({
            //     data: values,
            //     redirect: '/plans',
            //     isUpdate: !!id,
            //     url,
            //     setSubmitting,
            //     formik,
            // });
        },
        enableReinitialize: true,
    });
    useEffect(() => {
        if (defaultCurrency) {
            setSelectedCurrency(defaultCurrency);
            // formik.setValues('currencies_id', defaultCurrency.id ? parseInt(defaultCurrency.id, 10) : '');
        }
    }, [defaultCurrency]);
    useEffect(() => {
        dispatch(handleSearchParams({ page: 1, keyword: '' }));
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
    }, [searchParams]);
    const intialCsvValues = {
        csv: '',
    };
    const csvFormik = useFormik({
        initialValues: intialCsvValues,
        validationSchema: CsvRateFileValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setFieldError }) => {
            const url = `platformadmin/did_country_charges/import_csv/${id}`;
            const formData = new FormData();
            formData.append('csv', values.csv);
            const res = await handleGetWithPost({
                data: formData,
                url,
                method: 'POST',
            });
            if (res?.error) {
                const errors = res?.error?.data.errors;
                if (errors) {
                    Object.keys(errors).map((keyName) => setFieldError(keyName, errors[keyName]));
                } else if (res?.error.data?.message) {
                    Toast({ type: 'error', message: res?.error.data?.message });
                }
            }
            if (res?.data.status === 200) {
                Toast({ type: 'success', message: res?.data?.message });
                setOpenCSVForm(false);
                csvFormik.resetForm();
                refetchRate();
            }
        },
    });
    useEffect(() => {
    }, [csvFormik.errors]);
    const handleFileChange = (event) => {
        const uploadedFile = event.currentTarget.files[0];
        csvFormik.setFieldValue('csv', uploadedFile);
    };
    const handleDownloadSampleFile = async () => {
        try {
            const sampleFileUrl = `${process.env.REACT_APP_BASE_URL}platformadmin/did_country_charges/download-csv`;
            const response = await fetch(sampleFileUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'sample.csv';

            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            Toast({ type: 'error', message: error });
        }
    };
    const rateFormik = useFormik({
        initialValues: initialRateValues,
        validationSchema: platformPlanRateValidationSchema,
        onSubmit: async (values, { setFieldError }) => {
            values.country_id = parseInt(values.country_id, 10);
            values.local_number_call_charge = values.local_number_call_charge ? parseFloat(values.local_number_call_charge) : 0;
            values.local_number_rental_charge = values.local_number_rental_charge ? parseFloat(values.local_number_rental_charge) : 0;
            values.toll_free_number_call_charge = values.toll_free_number_call_charge ? parseFloat(values.toll_free_number_call_charge) : 0;
            values.toll_free_number_rental_charge = values.toll_free_number_rental_charge ? parseFloat(values.toll_free_number_rental_charge) : 0;
            values.forwarding_call_charge = values.forwarding_call_charge ? parseFloat(values.forwarding_call_charge) : 0;
            const url = rateId ? `platformadmin/did_country_charges/${rateId}` : `platformadmin/did_country_charges/${id}`;
            if (rateId) {
                values.pricing_plans_id = id;
                delete values.country;
                // values.pricing_plans_id = id.toString();
            }
            const res = rateId
                ? await handleUpdateOrDelete({
                    url: `${url}`,
                    data: values,
                    method: 'PUT',
                })
                : await handleGetWithPost({
                    data: values,
                    url,
                    method: rateId ? 'PUT' : 'POST',
                });
            if (res?.error) {
                const errors = res?.error?.data.fields;
                if (errors) {
                    Object.keys(errors).map((keyName) => setFieldError(keyName, errors[keyName]));
                } else if (res?.error.data?.message) {
                    Toast({ type: 'error', message: res?.error.data?.message });
                }
            }
            if (res?.data.status === 200 || res?.data.status === 201) {
                Toast({ type: 'success', message: res?.data?.message });

                setOpenrateForm(false);
                rateFormik.resetForm();
                refetchRate();
            }
            // handlePostRequest({
            //     data: values,
            //     // redirect: '/plans',
            //     isUpdate: !!rateId,
            //     url,
            //     setSubmitting,
            //     rateFormik,
            // });
        },
        enableReinitialize: true,
    });
    const getCountryInfo = (countryId, type) => {
        if (countries?.data) {
            const getCountryData = countries?.data?.filter((item) => parseInt(item.id, 10) === parseInt(countryId, 10));
            if (getCountryData) {
                if (type === 'rate') {
                    // setSelectedCurrency(getCountryData?.[0]);
                    rateFormik.setFieldValue('country_code', getCountryData?.[0]?.phonecode);
                } else {
                    // setSelectedCurrency(getCountryData?.[0]);
                }
            }
        }
    };
    const handlePlanSubmit = () => {
        if (Object.keys(formik.errors).length > 0) {
            const firstError = document.querySelector('.formik-error');
            if (firstError) {
                (firstError?.parentElement ?? firstError)?.scrollIntoView();
                formik.handleSubmit();
            }
        } else {
            formik.handleSubmit();
        }
    };
    useEffect(() => {
        getCountryInfo(formik.values.country_id);
    }, [countries, formik.values.country_id]);
    const handleCountryChange = (val) => {
        formik.setFieldValue('country_id', val);
        getCountryInfo(val);
    };
    const handleRateCountryChange = (val) => {
        // const val = e.target?.value ? e.target?.value : '';
        rateFormik.setFieldValue('country_id', val);
        // getCountryInfo(e.target.value, 'rate');
        getCountryInfo(val, 'rate');
    };
    const handleEnterprisePlan = useCallback(async (e) => {
        formik.setFieldValue('enterprice_plan', !!e.target.checked);
    }, []);
    useEffect(() => {
        if (data) {
            setServerData({
                ...data?.data,
            });
        }
    }, [data]);
    useEffect(() => {
        if (rateDetails?.data) {
            rateFormik.setFieldValue('country_id', rateDetails?.data?.country_id);
            rateFormik.setFieldValue('country_code', rateDetails?.data?.country_code);
            rateFormik.setFieldValue('local_number_rental_charge', rateDetails?.data?.local_number_rental_charge);
            rateFormik.setFieldValue('toll_free_number_rental_charge', rateDetails?.data?.toll_free_number_rental_charge);
            rateFormik.setFieldValue('local_number_call_charge', rateDetails?.data?.local_number_call_charge);
            rateFormik.setFieldValue('toll_free_number_call_charge', rateDetails?.data?.toll_free_number_call_charge);
            rateFormik.setFieldValue('forwarding_call_charge', rateDetails?.data?.forwarding_call_charge);
        }
    }, [rateDetails?.data]);
    const handleCSV = (() => {
        setOpenCSVForm(true);
    });
    const handleEditRate = ((val) => {
        setRateId(val?.id);
        setOpenrateForm(true);
    });
    const handleDeletePopup = (val) => {
        if (val.charges_is_delete === false) {
            Toast({ type: 'error', message: 'This rate cannot be deleted because it is currently associated with an active DID. Please unassign the DID before attempting to delete the rate' });
            return;
        }
        setDeleteUrl(`platformadmin/did_country_charges/${val.id}`);
        setShowDelete(true);
    };
    const handleDelete = async () => {
        // const datas = { status: updateData.status === 'live' ? 'paused' : 'live' };
        const res = await handleUpdateOrDelete({
            url: deleteUrl,
            method: 'DELETE',
        });
        if (res?.data.status === 200) {
            setShowDelete(false);
            setDeleteUrl('');
        }
    };
    const columns = [
        {
            Header: (
                <>
                    <span>COUNTRY</span>
                    <label htmlFor={id}>

                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    RATE COUNTRY
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),
            id: 'name',
            accessor: 'country_name',
        },
        {
            Header: (
                <>
                    <span>COUNTRY CODE</span>
                    <label htmlFor={id}>

                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    RATE COUNTRY CODE
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),
            id: 'country_code',
            accessor: 'country_code',
        },
        {
            Header:
            (
                <>
                    <span>LOCAL NUMBERS</span>
                    <label htmlFor={id}>
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    LOCAL NUMBERS PER MONTH
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),
            id: 'local_number_rental_charge',
            accessor: (row) => {
                if (row.local_number_call_charge) {
                    return <span>{`${selectedCurrency?.currency_symbol} ${row?.local_number_rental_charge}`}</span>;
                }
                return '';
            },
        },
        {
            Header:
            (
                <>
                    <span>TOLL-FREE NUMBERS</span>
                    <label htmlFor={id}>
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    TOLL-FREE NUMBERS PER MONTH
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),
            id: 'toll_free_number_rental_charge',
            accessor: (row) => {
                if (row.toll_free_number_rental_charge) {
                    return <span>{`${selectedCurrency?.currency_symbol} ${row?.toll_free_number_rental_charge}`}</span>;
                }
                return '';
            },
        },
        {
            Header:
            (
                <>
                    <span>LOCAL CALL CHARGE</span>
                    <label htmlFor={id}>
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    LOCAL CALL CHARGE(INBOUND LEG/MINUTE)
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),
            id: 'local_number_call_charge',
            accessor: (row) => {
                if (row.local_number_call_charge) {
                    return <span>{`${selectedCurrency?.currency_symbol} ${row?.local_number_call_charge}`}</span>;
                }
                return '';
            },
        },
        {
            Header: (
                <>
                    <span>TOLL-FREE CALL CHARGE</span>
                    <label htmlFor={id}>
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    TOLL-FREE CALL CHARGE(INBOUND LEG/MINUTE)
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),

            // <TooltipContainer text="TOLL-FREE CALL CHARGE(INBOUND LEG/MINUTE)">TOLL-FREE CALL CHARGE</TooltipContainer>),
            id: 'toll_free_number_call_charge',
            accessor: (row) => {
                if (row.toll_free_number_call_charge) {
                    return <span>{`${selectedCurrency?.currency_symbol} ${row?.toll_free_number_call_charge}`}</span>;
                }
                return '';
            },
        },
        {
            Header:
            (
                <>
                    <span> FORWARDING CALL CHARGE</span>
                    <label htmlFor={id}>
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    FORWARDING CALL CHARGE(PER MINUTE)
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />

                        </OverlayTrigger>
                    </label>
                </>
            ),
            id: 'forwarding_call_charge',
            accessor: (row) => {
                if (row.forwarding_call_charge) {
                    return <span>{`${selectedCurrency?.currency_symbol} ${row?.forwarding_call_charge}`}</span>;
                }
                return '';
            },
        },
        {
            Header: 'ACTION',
            accessor: (originalRow) => (
                <div className="d-flex align-items-center gap-2 action-lists">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={() => { handleEditRate(originalRow); }}>
                        <title>Edit</title>
                        <path d="M16.2114 8.67142C15.8878 8.67142 15.6254 8.93377 15.6254 9.25737V17.8514C15.6254 18.3899 15.1873 18.828 14.6488 18.828H2.1485C1.61001 18.828 1.17191 18.3899 1.17191 17.8514V5.35101C1.17191 4.81252 1.61001 4.37442 2.1485 4.37442H10.7425C11.0661 4.37442 11.3284 4.11207 11.3284 3.78847C11.3284 3.46487 11.0661 3.20251 10.7425 3.20251H2.1485C0.963816 3.20251 0 4.16633 0 5.35101V17.8514C0 19.036 0.963816 19.9999 2.1485 19.9999H14.6488C15.8335 19.9999 16.7973 19.036 16.7973 17.8514V9.25737C16.7973 8.93377 16.535 8.67142 16.2114 8.67142Z" />
                        <path d="M19.6002 1.50465L18.4952 0.399735C17.9622 -0.133327 17.0948 -0.133327 16.5617 0.399735L7.72267 9.23881C7.64087 9.32061 7.58513 9.42479 7.56239 9.53824L7.00991 12.3005C6.97151 12.4926 7.03163 12.6912 7.17019 12.8297C7.28117 12.9407 7.43067 13.0013 7.5845 13.0013C7.62274 13.0013 7.66118 12.9976 7.69939 12.99L10.4616 12.4375C10.5751 12.4148 10.6792 12.359 10.761 12.2772L19.6002 3.43818C19.6002 3.43818 19.6002 3.43818 19.6002 3.43814C20.1332 2.90512 20.1332 2.03775 19.6002 1.50465ZM10.0579 11.3231L8.33147 11.6685L8.6768 9.94207L15.8711 2.74761L17.2523 4.12875L10.0579 11.3231ZM18.7715 2.60952L18.0809 3.30009L16.6998 1.91896L17.3903 1.22843C17.4665 1.15226 17.5904 1.15222 17.6666 1.22839L18.7715 2.33331C18.8477 2.40944 18.8477 2.53339 18.7715 2.60952Z" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" onClick={() => { handleDeletePopup(originalRow); }}>
                        <title>Delete</title>
                        <path d="M14.8732 2.34369H12.1189V1.75775C12.1189 0.788493 11.3775 -6.10352e-05 10.4663 -6.10352e-05H6.05943C5.14821 -6.10352e-05 4.40686 0.788493 4.40686 1.75775V2.34369H1.65257C0.741344 2.34369 0 3.13224 0 4.1015C0 4.87994 0.478328 5.54138 1.13862 5.77185L2.12117 18.3881C2.19194 19.2919 2.91532 19.9999 3.76801 19.9999H12.7577C13.6104 19.9999 14.3338 19.2919 14.4046 18.3878L15.3871 5.77181C16.0474 5.54138 16.5257 4.87994 16.5257 4.1015C16.5257 3.13224 15.7844 2.34369 14.8732 2.34369ZM5.50858 1.75775C5.50858 1.43466 5.75569 1.17181 6.05943 1.17181H10.4663C10.77 1.17181 11.0172 1.43466 11.0172 1.75775V2.34369H5.50858V1.75775ZM13.3067 18.2908C13.2831 18.592 13.042 18.8281 12.7577 18.8281H3.76801C3.48381 18.8281 3.24268 18.592 3.21914 18.2911L2.25095 5.85931H14.2748L13.3067 18.2908ZM14.8732 4.68744H1.65257C1.34883 4.68744 1.10172 4.42458 1.10172 4.1015C1.10172 3.77841 1.34883 3.51556 1.65257 3.51556H14.8732C15.1769 3.51556 15.424 3.77841 15.424 4.1015C15.424 4.42458 15.1769 4.68744 14.8732 4.68744Z" />
                        <path d="M6.16797 17.1816L5.64454 8.19904C5.62664 7.89211 5.37689 7.65868 5.08973 7.67779C4.80117 7.69683 4.58178 7.96104 4.59965 8.26793L5.12309 17.2505C5.14029 17.5457 5.37074 17.7729 5.64503 17.7729C5.94817 17.7729 6.18657 17.5013 6.16797 17.1816Z" />
                        <path d="M8.26294 7.67676C7.97386 7.67676 7.7395 7.92604 7.7395 8.23353V17.2161C7.7395 17.5236 7.97386 17.7729 8.26294 17.7729C8.55202 17.7729 8.78638 17.5236 8.78638 17.2161V8.23353C8.78638 7.92604 8.55202 7.67676 8.26294 7.67676Z" />
                        <path d="M11.4357 7.67781C11.1478 7.65877 10.8988 7.89213 10.8809 8.19906L10.3575 17.1816C10.3396 17.4885 10.559 17.7527 10.8475 17.7718C11.1362 17.7908 11.3845 17.5573 11.4023 17.2505L11.9258 8.26795C11.9436 7.96102 11.7243 7.69682 11.4357 7.67781Z" />
                    </svg>
                </div>
            ),
            id: 'ACTION',
        },
    ];
    const handleNext = () => {
        setOpenrateForm(true);
        rateFormik.setFieldValue('country_id', '');
    };
    const handlePage = (value) => {
        setPage(value);
        const checkKeyWordExist = searchParams;
        if (checkKeyWordExist?.keyword !== undefined) {
            dispatch(handleSearchParams({ page: value, keyword: checkKeyWordExist?.keyword }));
        } else {
            dispatch(handleSearchParams({ page: value }));
        }
    };

    const handleCancel = () => {
        dispatch(handleSearchParams({ page: 1, search: '' }));
        navigate('/plans');
    };
    const handleCloseRateModal = (() => {
        setOpenrateForm(false);
        rateFormik.resetForm();
        setRateId('');
    });
    const handleRateSubmit = () => {
        rateFormik.handleSubmit();
    };
    return (
        <>
            <CommonModdal
                show={showDelete}
                type=""
                classAddon="theme-cyan text-center footer-center"
                size="small"
                heading=""
                openSubmitModal={handleDelete}
                handleClose={() => {
                    setShowDelete(false);
                    setDeleteUrl('');
                }}
                footerButtons1="Yes"
                footerButtons2="No"
                isDelete
            >
                <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
                <p>
                    Are you sure want to delete this rate?
                </p>
            </CommonModdal>
            <CommonModdal
                show={openCSVForm}
                type="closeOpt"
                classAddon="theme-cyan create-target import-rate"
                size="large"
                heading=""
                openSubmitModal={csvFormik.handleSubmit}
                handleClose={() => {
                    setOpenCSVForm(false);
                }}
                footerButtons1="Import"
                footerButtons2="Cancel"
            >
                <div className="col-12 p-0">
                    <div className="card themeTabs body">
                        <div className="card-tab-header">
                            <ul className="nav nav-tabs-new">
                                <li className="nav-item mr-1 active">
                                    <div className="nav-link active">
                                        Import CSV
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content border-0 p-0">
                            <form>
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <button type="button" className="btn btn-gray mb-3" onClick={handleDownloadSampleFile}>
                                            <i className="fa fa-download me-2" />
                                            Download Sample File
                                        </button>
                                        <div>
                                            <TooltipContainer label="Import File" text="To import a file, click the 'Upload' button and select your file from the system" required />
                                        </div>
                                        <div className="form-group mb-2 upload-preview-section">
                                            <label htmlFor="csv" className="form-control">
                                                {csvFormik.values.csv?.name}
                                            </label>
                                            <div className="input-group-append position-relative">
                                                <input
                                                    id="csv"
                                                    name="csv"
                                                    className="upload-payment-logo"
                                                    type="file"
                                                    accept=".csv"
                                                    onChange={handleFileChange}
                                                />
                                                <button
                                                    className="btn btn-info"
                                                    type="button"
                                                    htmlFor="upl"
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </div>
                                        <ErrorMessageComponent error={csvFormik.errors.csv} touched={csvFormik.touched.csv} />
                                        <p className="small-text mt-1 gray-text">Upload a CSV file that has rate information.</p>
                                        <p className="mb-0 gray-text">
                                            <strong>Notes: </strong>
                                        </p>
                                        <p className="notes-fonts gray-text">
                                            If you are you are using MS Excel to create the CSV import. Please follow the instructions.
                                            <ul>
                                                <li>Select all cells. You can use CTRL + A.</li>
                                                <li>Then Right-click and Click on the Format Cell Option</li>
                                                <li>From the available format select the text and save it.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </CommonModdal>
            <CommonModdal
                show={openRateForm}
                type="closeOpt"
                classAddon="theme-cyan create-target"
                size="xl"
                heading="Rates"
                handleClose={handleCloseRateModal}
                footerButtons1="Submit"
                footerButtons2="Cancel"
                openSubmitModal={handleRateSubmit}
            >
                <div className="col-12 p-0">
                    <div className="body themeTabs">
                        <div className="tab-content card border-0 p-0 mb-0">
                            {/* Add camp  section :: BEGIN */}
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer label="Country" id="country_id" text="Select the country for which you want to create a plan parameter." required>
                                            <div className="input-group mb-3 dial-code pr-0">
                                                {/* <select className="form-control" name="plan_mode" value={rateFormik.values.country_id} onChange={handleRateCountryChange}>
                                                    <option value="">Select Country</option>
                                                    {countries?.data?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select> */}
                                                <SearchableSelect modalEl=".modal" label="Country" options={countries?.data} onChange={handleRateCountryChange} value={rateFormik?.values?.country_id} allowClear={false} />
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.country_id} touched={rateFormik.touched.country_id} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer
                                            label="Country Code"
                                            id="local_number_rental_charge"
                                            text=" The country code associated with the country."
                                        >
                                            <div className="input-group mb-3">
                                                <input
                                                    name="country_code"
                                                    value={rateFormik.values.country_code || ''}
                                                    onChange={rateFormik.handleChange}
                                                    label="Country Code"
                                                    type="text"
                                                    className="form-control"
                                                    readOnly
                                                />
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.country_code} touched={rateFormik.touched.country_code} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer
                                            label="Local Numbers"
                                            id="local_number_rental_charge"
                                            text="The monthly charge for maintaining a local phone number."
                                            required
                                        >
                                            <div className="input-group mb-3">
                                                <div className="currency-symbol-input">
                                                    {selectedCurrency?.currency_symbol}
                                                </div>
                                                <input
                                                    name="local_number_rental_charge"
                                                    value={rateFormik.values.local_number_rental_charge}
                                                    onChange={rateFormik.handleChange}
                                                    label="Local Numbers"
                                                    type="number"
                                                    className="form-control"
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        Per Month
                                                    </span>
                                                </div>
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.local_number_rental_charge} touched={rateFormik.touched.local_number_rental_charge} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer
                                            label="Toll Free Numbers"
                                            id="toll_free_number_call_charge"
                                            text="The monthly charge for maintaining toll-free phone numbers."
                                            required
                                        >
                                            <div className="input-group mb-3">
                                                <div className="currency-symbol-input">
                                                    {selectedCurrency?.currency_symbol}
                                                </div>
                                                <input
                                                    name="toll_free_number_rental_charge"
                                                    value={rateFormik.values.toll_free_number_rental_charge}
                                                    onChange={rateFormik.handleChange}
                                                    label="Toll Free Numbers"
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        Per Month
                                                    </span>
                                                </div>
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.toll_free_number_rental_charge} touched={rateFormik.touched.toll_free_number_rental_charge} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer
                                            label="Local Call Charge"
                                            id="local_number_call_charge"
                                            required
                                            text="The cost per minute for inbound local calls."
                                        >
                                            <div className="input-group mb-3">
                                                <div className="currency-symbol-input">
                                                    {selectedCurrency?.currency_symbol}
                                                </div>
                                                <input
                                                    name="local_number_call_charge"
                                                    value={rateFormik.values.local_number_call_charge}
                                                    onChange={rateFormik.handleChange}
                                                    label="Local Call Charge"
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        {' '}
                                                        Per Minute
                                                    </span>
                                                </div>
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.local_number_call_charge} touched={rateFormik.touched.local_number_call_charge} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer
                                            label="Toll Free Call Charge"
                                            id="toll_free_number_call_charge"
                                            required
                                            text="The cost per minute for inbound toll-free calls."
                                        >
                                            <div className="input-group mb-3">
                                                <div className="currency-symbol-input">
                                                    {selectedCurrency?.currency_symbol}
                                                </div>
                                                <input
                                                    name="toll_free_number_call_charge"
                                                    value={rateFormik.values.toll_free_number_call_charge}
                                                    onChange={rateFormik.handleChange}
                                                    label="Toll Free Call Charge"
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        {' '}
                                                        Per Minute
                                                    </span>
                                                </div>
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.toll_free_number_call_charge} touched={rateFormik.touched.toll_free_number_call_charge} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer
                                            label="Forwarding Call Charge"
                                            id="forwarding_call_charge"
                                            required
                                            text="The cost per minute for forwarding calls."
                                        >
                                            <div className="input-group mb-3">
                                                <div className="currency-symbol-input">
                                                    {selectedCurrency?.currency_symbol}
                                                </div>
                                                <input
                                                    name="forwarding_call_charge"
                                                    value={rateFormik.values.forwarding_call_charge}
                                                    onChange={rateFormik.handleChange}
                                                    label="Forwarding Call Charge"
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        {' '}
                                                        Per Minute
                                                    </span>
                                                </div>
                                            </div>
                                        </TooltipContainer>
                                        <ErrorMessageComponent error={rateFormik.errors.forwarding_call_charge} touched={rateFormik.touched.forwarding_call_charge} />
                                    </div>
                                    <div className="col-12">
                                        <div className="divider-set" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonModdal>
            <div className="container-fluid">
                {(dataLoading || isLoading) && <Loader />}
                <PageHeader
                    HeaderText="Plan Management"
                    Breadcrumb={[
                        { name: 'Plan Management', navigate: '/plans' },
                        { name: id ? 'Edit Plan Parameter' : 'Create Plan Parameter', navigate: '' },
                    ]}
                />
                <div className="col-12 p-0 create-target route-add form-data">
                    <div className="card themeTabs body">
                        <div className="card-tab-header">
                            <ul className="nav nav-tabs-new" role="tablist">
                                <li
                                    className="nav-item mr-1 active"
                                    id="bacicTab2-1"
                                    role="presentation"
                                    onClick={() => {
                                        onTabChange(1);
                                    }}
                                >
                                    <a className="nav-link active" data-toggle="tab">
                                        Basic Details
                                        {/* {id ? 'Edit Plan' : 'Add Plan'} */}
                                    </a>
                                </li>
                                {id && (
                                    <li
                                        className="nav-item mr-1"
                                        id="bacicTab2-2"
                                        role="presentation"
                                        onClick={() => {
                                            onTabChange(2);
                                        }}
                                    >
                                        <a className="nav-link" data-toggle="tab">
                                            <img alt="text" src={tickIcon} className="mr-2" />
                                            Rates
                                        </a>
                                    </li>
                                )}

                            </ul>
                        </div>
                        <div className="tab-content border-0 p-0">
                            <div id="bacicTab2pan-1" className="tab-pane show active p-3">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 col-12 ">
                                            <TooltipContainer label="Country" id="country_id" text="Select the country for which you want to create a plan parameter." required>
                                                <div className="input-group mb-3">
                                                    <SearchableSelect label="Country" options={countries?.data} onChange={handleCountryChange} value={formik?.values?.country_id} />
                                                </div>
                                            </TooltipContainer>
                                            <ErrorMessageComponent error={formik.errors.country_id} touched={formik.touched.country_id} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <InputWithTooltip
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                label="Plan Name"
                                                text="Enter the name of the plan for which you're defining parameters."
                                                required
                                            />
                                            <ErrorMessageComponent error={formik.errors.name} touched={formik.touched.name} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <InputWithTooltip
                                                name="description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                label="Plan Description"
                                                text="Provide a description for the plan, explaining its purpose or features."
                                            />
                                            <ErrorMessageComponent error={formik.errors.description} touched={formik.touched.description} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <TooltipContainer
                                                label="Plan Type"
                                                id="plan_mode"
                                                text="Select the appropriate plan mode that best fits your needs."
                                                required
                                            >
                                                <div className="d-flex align-items-center gap-2 position-relative">
                                                    <select className="form-control" name="plan_mode" value={formik.values.plan_mode} onChange={formik.handleChange}>
                                                        <option className="opacity5" value="">Select</option>
                                                        <option value="monthly">Monthly</option>
                                                        <option value="yearly">Yearly</option>
                                                    </select>
                                                </div>
                                                {formik.errors.plan_mode && (
                                                    <ErrorMessageComponent error={formik.errors.plan_mode} touched={formik.touched.plan_mode} />
                                                )}
                                            </TooltipContainer>

                                        </div>
                                        <div className="col-md-6 col-12">
                                            <TooltipContainer
                                                label="Platform Fee"
                                                id="platform_fee"
                                                required
                                                text="The platform fee charged for using the platform."
                                            >

                                                <div className="input-group mb-3">
                                                    <div className="currency-symbol-input">
                                                        {selectedCurrency?.currency_symbol}
                                                    </div>
                                                    <input
                                                        name="platform_charge"
                                                        value={formik.values.platform_charge}
                                                        onChange={formik.handleChange}
                                                        label="Platform Charge"
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            {' '}
                                                            {formik.values.plan_mode === 'monthly' ? 'Per Month' : (formik.values.plan_mode === 'yearly' ? 'Per Year' : '')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </TooltipContainer>
                                            <ErrorMessageComponent error={formik.errors.platform_charge} touched={formik.touched.platform_charge} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <TooltipContainer
                                                label="Recording"
                                                id="recording_fee"
                                                required
                                                text="The fee for recording calls, charged per recording."
                                            >
                                                <div className="input-group mb-3">
                                                    <div className="currency-symbol-input">
                                                        {selectedCurrency?.currency_symbol}
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        value={formik.values.recording_call_charge}
                                                        onChange={formik.handleChange}
                                                        name="recording_call_charge"
                                                        type="number"
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            {' '}
                                                            Per Minute
                                                        </span>
                                                    </div>
                                                </div>
                                                {formik.errors.recording_call_charge && (
                                                    <ErrorMessageComponent error={formik.errors.recording_call_charge} touched={formik.touched.recording_call_charge} />
                                                )}
                                            </TooltipContainer>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <TooltipContainer
                                                label="SIP Forwarding Charge"
                                                id="sip_forwarding_cost"
                                                required
                                                text="Cost for forwarding calls to an external destination via SIP."
                                            >
                                                <div className="input-group mb-3">
                                                    <div className="currency-symbol-input">
                                                        {selectedCurrency?.currency_symbol}
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        value={formik.values.sip_forwarding_cost}
                                                        onChange={formik.handleChange}
                                                        name="sip_forwarding_cost"
                                                        type="number"
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            {' '}
                                                            Per Minute
                                                        </span>
                                                    </div>
                                                </div>
                                                {formik.errors.sip_forwarding_cost && (
                                                    <ErrorMessageComponent error={formik.errors.sip_forwarding_cost} touched={formik.touched.sip_forwarding_cost} />
                                                )}
                                            </TooltipContainer>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <TooltipContainer
                                                label="Storage Charge"
                                                id="storage_fee"
                                                required
                                                text="The cost for storing call recordings and data on the platform."
                                            >
                                                <div className="input-group mb-3">
                                                    <div className="currency-symbol-input">
                                                        {selectedCurrency?.currency_symbol}
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        value={formik.values.recording_storage_charge}
                                                        onChange={formik.handleChange}
                                                        name="recording_storage_charge"
                                                        type="number"
                                                    />
                                                    {/* <span className="input-group-text"> */}
                                                    {/* <span style={{
                                                        position: 'absolute', left: '10px', top: '13px', color: 'gray', fontSize: 'smaller',
                                                    }}
                                                    >
                                                        {' '}
                                                        {selectedCurrency?.currency_symbol}
                                                        {' '}
                                                    </span> */}
                                                    {/* </span> */}
                                                    <div className="p-2" />
                                                    <div className="currency-symbol-input">
                                                        {selectedCurrency?.currency_symbol}
                                                    </div>
                                                    <input
                                                        className="form-control w-65px"
                                                        value={formik.values.storage_limit}
                                                        onChange={formik.handleChange}
                                                        name="storage_limit"
                                                        type="number"
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            GB Per Month
                                                        </span>
                                                    </div>
                                                </div>
                                                {formik.errors.recording_storage_charge && (
                                                    <ErrorMessageComponent error={formik.errors.recording_storage_charge} touched={formik.touched.recording_storage_charge} />
                                                )}
                                                {formik.errors.storage_limit && (
                                                    <ErrorMessageComponent error={formik.errors.storage_limit} touched={formik.touched.storage_limit} />
                                                )}
                                            </TooltipContainer>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <InputWithTooltip
                                                name="priority"
                                                value={formik.values.priority || ''}
                                                onChange={formik.handleChange}
                                                label="Priority"
                                                type="number"
                                                text="Set the priority level to determine the order of plan items within your all plan"
                                                required
                                            />
                                            <ErrorMessageComponent error={formik.errors.priority} touched={formik.touched.priority} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <TooltipContainer label="Enterprise Plan" id="plan-status" text="If toggle is on then it is Enterprise Plan otherwise it is Global Plan." />
                                            <div>
                                                <label id="plan-switch" className="switch ms-0" htmlFor="plan-status">
                                                    <input
                                                        type="checkbox"
                                                        id="plan-status"
                                                        onChange={handleEnterprisePlan}
                                                        checked={Boolean(formik.values.enterprice_plan)}
                                                        name="plan-status"
                                                    />
                                                    <span className="slider round" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 pt-4">
                                            <button type="button" className="btn btn-primary mr-3 " onClick={handlePlanSubmit}>{` ${id ? 'Update' : 'Add'}`}</button>
                                            <button type="button" className="btn btn-secondary mr-3 " onClick={handleCancel}>Cancel</button>
                                            {id && (
                                                <button type="button" className="btn btn-primary mr-3" onClick={() => onTabChange(2)}>Next</button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="bacicTab2pan-2" className="tab-pane p-0">
                                <div className="row p-4 pb-0">

                                    <div className="col-md-6 col-12 prepend-input">
                                        <div className="input-group mb-3 w-250px">
                                            <div className="input-group-prepend p-2">
                                                <i className="fa fa-search" />
                                            </div>
                                            <input
                                                aria-label=""
                                                value={searchParams.search}
                                                className="form-control"
                                                placeholder="Search"
                                                onChange={(e) => {
                                                    dispatch(handleSearchParams({ ...searchParams, keyword: e.target.value }));
                                                }}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 text-md-end mb-3 mb-md-0">
                                        <button className="btn btn-secondary secondary-importbtn mr-2" type="button" onClick={handleCSV}>Import CSV</button>
                                        <button className="btn btn-info" type="button" onClick={handleNext}>Create Rates</button>
                                    </div>

                                    <div className="col-12 without-header p-0 mb-2 mb-md-0">
                                        <CommonTable
                                            columns={columns}
                                            data={rates?.data || []}
                                            isFetching={isFetching}
                                            isLoading={isLoading}
                                            width={'165%'}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <PaginationComponent
                                            itemsCount={rates?.pagination?.total || 1}
                                            itemsPerPage={10}
                                            currentPage={page}
                                            dataLength={rates?.data?.length}
                                            setCurrentPage={handlePage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
AddPlan.propTypes = {
    dataLoading: PropTypes.bool.isRequired,
};

export default withPostContainer(AddPlan, 'platformadmin/plan/');
