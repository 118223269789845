/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import BasicTable from '../../components/Tables/BasicTable';
import withGetContainer from '../../Container/withGetContainer';
import PaginationComponent from '../../components/Common/Pagination';
import { handleSearchParams } from '../../app/features/CommonSlice';
import { addPageLoadActivityLog, getModulePermission } from '../../Util';
import { MAX_LENGTH } from '../../Constant';

const ExpensesList = ({
    data, isLoading, isFetching, handleDeleteMethod,
}) => {
    const { user } = useSelector((store) => store.auth);
    getModulePermission('view', 'page');
    const updatePermission = getModulePermission('update', 'button');
    const deletePermission = getModulePermission('delete', 'button');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { searchParams } = useSelector((store) => store.common);
    const [page, setPage] = useState(1);
    const [filterOptions, setFilterOptions] = useState(['Campaign', 'Marketing Platform']);

    useEffect(() => {
        dispatch(handleSearchParams({ page: 1, keyword: '' }));
        window.scrollTo(0, 0);
        addPageLoadActivityLog('Expenses');
    }, []);
    const handleEdit = useCallback((value) => () => {
        navigate(`edit-expenses/${value.id}`);
    }, []);
    const columns = [
        {
            Header: 'DATE',
            id: 'date',
            accessor: 'date',
        },
        {
            Header: 'CAMPAIGN',
            id: 'campaign_name',
            accessor: (row) => (
                <p title={row?.campaign_name} className="mb-0">
                    {row?.campaign_name ? (row?.campaign_name.length >= MAX_LENGTH ? `${row?.campaign_name?.substring(0, MAX_LENGTH)}...` : row?.campaign_name) : ''}
                </p>
            ),
        },
        {
            Header: 'MARKETING PLATFORM',
            id: 'allowed-country-name',
            accessor: (row) => (
                <div className="gap-2">
                    {row?.marketing_platforms?.map((i, key) => (
                        <span title={`Expense value is ${user?.currency?.currency_symbol} ${parseFloat(i?.expense_value, 10)}`} key={key}>
                            {i?.marketingplatform_info?.name}
                            {(key + 1) !== row?.marketing_platforms?.length ? ',' : '' }
                        </span>
                    ))}
                </div>
            ),
        },
        {
            Header: 'TOTAL AMOUNT',
            id: 'total_amount',
            accessor: (row) => {
                if (row?.marketing_platforms) {
                    const amountArray = row?.marketing_platforms.map((item) => (item.expense_value ? parseFloat(item.expense_value, 10) : 0));
                    return (
                        <span>
                            {user?.currency?.currency_symbol}
                            {amountArray.reduce((a, b) => a + b, 0).toFixed(2)}
                        </span>
                    );
                }
                return '';
            },
        },
        {
            Header: 'ACTIONS',
            accessor: (originalRow) => {
                const associatedTotal = (typeof originalRow.ctn !== 'undefined' && originalRow.ctn !== '' && originalRow.ctn !== 0);
                return (
                    <div className="d-flex align-items-center gap-2 action-lists">
                        { updatePermission && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={handleEdit(originalRow)} title="Edit">
                                <title>Edit</title>
                                <path d="M16.2114 8.67142C15.8878 8.67142 15.6254 8.93377 15.6254 9.25737V17.8514C15.6254 18.3899 15.1873 18.828 14.6488 18.828H2.1485C1.61001 18.828 1.17191 18.3899 1.17191 17.8514V5.35101C1.17191 4.81252 1.61001 4.37442 2.1485 4.37442H10.7425C11.0661 4.37442 11.3284 4.11207 11.3284 3.78847C11.3284 3.46487 11.0661 3.20251 10.7425 3.20251H2.1485C0.963816 3.20251 0 4.16633 0 5.35101V17.8514C0 19.036 0.963816 19.9999 2.1485 19.9999H14.6488C15.8335 19.9999 16.7973 19.036 16.7973 17.8514V9.25737C16.7973 8.93377 16.535 8.67142 16.2114 8.67142Z" />
                                <path d="M19.6002 1.50465L18.4952 0.399735C17.9622 -0.133327 17.0948 -0.133327 16.5617 0.399735L7.72267 9.23881C7.64087 9.32061 7.58513 9.42479 7.56239 9.53824L7.00991 12.3005C6.97151 12.4926 7.03163 12.6912 7.17019 12.8297C7.28117 12.9407 7.43067 13.0013 7.5845 13.0013C7.62274 13.0013 7.66118 12.9976 7.69939 12.99L10.4616 12.4375C10.5751 12.4148 10.6792 12.359 10.761 12.2772L19.6002 3.43818C19.6002 3.43818 19.6002 3.43818 19.6002 3.43814C20.1332 2.90512 20.1332 2.03775 19.6002 1.50465ZM10.0579 11.3231L8.33147 11.6685L8.6768 9.94207L15.8711 2.74761L17.2523 4.12875L10.0579 11.3231ZM18.7715 2.60952L18.0809 3.30009L16.6998 1.91896L17.3903 1.22843C17.4665 1.15226 17.5904 1.15222 17.6666 1.22839L18.7715 2.33331C18.8477 2.40944 18.8477 2.53339 18.7715 2.60952Z" />
                            </svg>
                        )}
                        { deletePermission && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" title="Delete" onClick={handleDeleteMethod(`expense/${originalRow.id}`, associatedTotal, 'The expenses cannot be deleted because it has been assigned to an advertiser.')}>
                                <title>Delete</title>
                                <path d="M14.8732 2.34369H12.1189V1.75775C12.1189 0.788493 11.3775 -6.10352e-05 10.4663 -6.10352e-05H6.05943C5.14821 -6.10352e-05 4.40686 0.788493 4.40686 1.75775V2.34369H1.65257C0.741344 2.34369 0 3.13224 0 4.1015C0 4.87994 0.478328 5.54138 1.13862 5.77185L2.12117 18.3881C2.19194 19.2919 2.91532 19.9999 3.76801 19.9999H12.7577C13.6104 19.9999 14.3338 19.2919 14.4046 18.3878L15.3871 5.77181C16.0474 5.54138 16.5257 4.87994 16.5257 4.1015C16.5257 3.13224 15.7844 2.34369 14.8732 2.34369ZM5.50858 1.75775C5.50858 1.43466 5.75569 1.17181 6.05943 1.17181H10.4663C10.77 1.17181 11.0172 1.43466 11.0172 1.75775V2.34369H5.50858V1.75775ZM13.3067 18.2908C13.2831 18.592 13.042 18.8281 12.7577 18.8281H3.76801C3.48381 18.8281 3.24268 18.592 3.21914 18.2911L2.25095 5.85931H14.2748L13.3067 18.2908ZM14.8732 4.68744H1.65257C1.34883 4.68744 1.10172 4.42458 1.10172 4.1015C1.10172 3.77841 1.34883 3.51556 1.65257 3.51556H14.8732C15.1769 3.51556 15.424 3.77841 15.424 4.1015C15.424 4.42458 15.1769 4.68744 14.8732 4.68744Z" />
                                <path d="M6.16797 17.1816L5.64454 8.19904C5.62664 7.89211 5.37689 7.65868 5.08973 7.67779C4.80117 7.69683 4.58178 7.96104 4.59965 8.26793L5.12309 17.2505C5.14029 17.5457 5.37074 17.7729 5.64503 17.7729C5.94817 17.7729 6.18657 17.5013 6.16797 17.1816Z" />
                                <path d="M8.26294 7.67676C7.97386 7.67676 7.7395 7.92604 7.7395 8.23353V17.2161C7.7395 17.5236 7.97386 17.7729 8.26294 17.7729C8.55202 17.7729 8.78638 17.5236 8.78638 17.2161V8.23353C8.78638 7.92604 8.55202 7.67676 8.26294 7.67676Z" />
                                <path d="M11.4357 7.67781C11.1478 7.65877 10.8988 7.89213 10.8809 8.19906L10.3575 17.1816C10.3396 17.4885 10.559 17.7527 10.8475 17.7718C11.1362 17.7908 11.3845 17.5573 11.4023 17.2505L11.9258 8.26795C11.9436 7.96102 11.7243 7.69682 11.4357 7.67781Z" />
                            </svg>
                        )}
                    </div>
                );
            },
            id: 'ACTION',
        },
    ];
    const handleNext = () => {
        navigate('add-expenses');
    };
    const handlePage = (value) => {
        setPage(value);
        dispatch(handleSearchParams({ ...searchParams, page: value }));
    };
    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Expenses"
                    Breadcrumb={[
                        { name: 'Expenses', navigate: '/expenses' },
                    ]}
                />
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card border-0 borderRadius-10 plan-data-show">
                            <BasicTable
                                data={data?.data || []}
                                columns={columns}
                                btnText="CREATE EXPENSE"
                                handleClick={handleNext}
                                title="Expenses List"
                                showCommonChipsFilter
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                                isFetching={isFetching}
                                isLoading={isLoading}
                                createButton
                                deletePermission={deletePermission}
                                editPermission={updatePermission}
                            />
                        </div>
                        <PaginationComponent
                            itemsCount={data.pagination?.total || 1}
                            itemsPerPage={10}
                            currentPage={page}
                            dataLength={data?.data?.length}
                            setCurrentPage={handlePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
ExpensesList.propTypes = {
    data: PropTypes.objectOf([object]),
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    handleDeleteMethod: PropTypes.func.isRequired,
};
ExpensesList.defaultProps = {
    data: [],
};
export default withGetContainer({ WrappedComponent: ExpensesList, url: 'expense/?items_per_page=10', module: 'expense' });
